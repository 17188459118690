<template>
  <form @submit.prevent="submit" @keydown.enter="handleEnterEvent">
    <fieldset class="form-wrapper__input-group">
      <legend class="s-is-hidden">Preencha os campos de cep e especialidade:</legend>

      <InputText
        id="postal-code"
        ref="postalCode"
        v-model="state.postalCode"
        label="Informe o CEP"
        mask="#####-###"
        input-mode="numeric"
        :is-raw="true"
        :errors="v$.postalCode.$errors"
        @update:model-value="handlePostalCodeChange($event)"
      />

      <Spacer mobile="16px" desktop="0" />

      <Select
        ref="specialties"
        v-model="state.specialty"
        custom-label="Especialidade"
        select-value="slug"
        input-id="postal-code-specialty"
        label="name"
        api-route="/v1/general/lists/specialties"
        options-icon="icon-note"
        :default-option="{ name: 'Todas as especialidades', slug: 'all' }"
        :params="{ per_page: 16 }"
        :errors="v$.specialty.$errors"
        @raw-value="handleSpecialtyChange($event)"
      />
    </fieldset>

    <Spacer mobile="16px" desktop="32px" />

    <footer>
      <button :class="{ 'm-button-primary': true, '--is-loading': isLoading }" type="submit">
        <i class="icon-search-status --icon-default" aria-hidden="true"></i>
        Pesquisar

        <Spinner />
      </button>

      <Spacer mobile="32px" desktop="0" />

      <FormDetail />
    </footer>
  </form>
</template>

<script>
import { computed, reactive } from "vue";

import useValidate from "@vuelidate/core";
import { helpers, minLength, required } from "@vuelidate/validators";

import { searchType } from "../../../types/searchType";

import focusOnFieldByRef from "../../../utils/focus";

import Spacer from "../../Spacer/index.vue";
import Spinner from "../../Spinner/index.vue";
import Select from "../../inputs/Select/index.vue";
import InputText from "../../inputs/Text/index.vue";
import FormDetail from "../../items/FormDetail/index.vue";

export default {
  components: {
    Spacer,
    Select,
    InputText,
    Spinner,
    FormDetail,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: String,
      default: "0",
    },
  },
  emits: ["terms"],
  setup() {
    const requiredMessage = "Este campo é obrigatório";

    const state = reactive({
      postalCode: "",
      specialty: "",
    });

    const rules = computed(() => {
      return {
        postalCode: {
          required: helpers.withMessage(requiredMessage, required),
          minLength: helpers.withMessage("Preencha com um CEP válido", minLength(8)),
        },
        specialty: {
          required: helpers.withMessage(requiredMessage, required),
        },
      };
    });

    const v$ = useValidate(rules, state);
    return { state, v$ };
  },
  data() {
    return {
      specialtyFormatted: "",
      searchType: searchType,
      isRouted: {
        type: Boolean,
        default: true,
      },
      canSearchOnEnter: false,
    };
  },
  async mounted() {
    this.isRouted = this.$route;

    if (!this.isRouted) return;

    setTimeout(() => {
      this.bindQueryParamsAndSearch();
    }, 250);
  },
  methods: {
    async submit() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        const { state, specialtyFormatted } = this;
        const { postalCode, specialty } = state || {};

        this.$emit("terms", {
          zip_code: postalCode,
          zip_specialty: specialty,
          zip_specialty_label: specialtyFormatted,
        });
      }
    },
    async handlePostalCodeChange() {
      const isValid = await this.v$.postalCode.$validate();
      if (isValid) focusOnFieldByRef(this, "specialties");

      this.canSearchOnEnter = false;

      this.mergeQueryString({ zip_code: this.state.postalCode });
    },
    async handleSpecialtyChange({ slug = "", name = "" }) {
      await this.v$.postalCode.$validate();
      this.updateSpecialtyFormatted(name);
      this.mergeQueryString({ zip_specialty: slug, zip_specialty_label: name });

      setTimeout(() => {
        this.canSearchOnEnter = true;
      }, 250);
    },
    mergeQueryString(params = {}) {
      if (!this.isRouted) return;

      this.$router?.replace({ query: { ...this.$route?.query, ...params } });
    },
    updateSpecialtyFormatted(name = "") {
      this.specialtyFormatted = name;
    },
    bindQueryParamsAndSearch() {
      const { zip_code = "", zip_specialty = "", zip_specialty_label = "" } = this.$route?.query;

      if (zip_code) {
        this.state.postalCode = zip_code;
        this.$refs.postalCode.value = zip_code;
      }

      if (zip_specialty) {
        this.state.specialty = zip_specialty;
        this.$refs.specialties.value = {
          slug: zip_specialty,
          name: zip_specialty_label,
        };
        this.updateSpecialtyFormatted(zip_specialty_label);
      }

      const hasAllParameters = !!zip_code && !!zip_specialty && !!zip_specialty_label;

      this.$nextTick(async () => {
        if (hasAllParameters && this.currentStep === this.searchType.PostalCode)
          await this.submit();
      });
    },
    handleEnterEvent() {
      const { canSearchOnEnter = false, state } = this;
      const { postalCode = "", specialty = "" } = state;

      if (!!postalCode && !!specialty && canSearchOnEnter) this.submit();
    },
  },
};
</script>
